import React from 'react';
import styled from 'styled-components';
import { Text, Divider, useTheme } from '@chakra-ui/core';

import ActivityCard from './ActivityCard';
import { mediaQueries } from '../../helpers';
import { getOrderedActivities } from './utils';

const ActivitiesWrapper = styled.div`
  width: 100%;
  padding: 40px 0px;

  ${mediaQueries.laptop(`
    padding: 8px 0px;
  `)}
`;

const ActivitiesGridWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 42px;
  grid-row-gap: 42px;

  ${mediaQueries.laptop(`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  `)}
`;

const ActivityKindSeparator = styled.div`
  width: 100%;
  text-align: center;
  align-self: start;
  padding: 16px;
  margin: 32px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ActivitiesGrid = props => {
  const { activities } = props;
  const theme = useTheme();

  const test_orderedActivities = getOrderedActivities(activities);

  return (
    <ActivitiesWrapper>
      {test_orderedActivities.map(item => (
        <>
          <ActivityKindSeparator color="gray.500">
            <Text
              fontSize="2xl"
              color="gray.500"
              backgroundColor={theme.colors.white}
              width="260px"
              zIndex="1"
            >
              {item.kind}
            </Text>
            <Divider marginTop="-18px" borderColor="gray.500" width="100%" />
          </ActivityKindSeparator>
          <ActivitiesGridWrapper>
            {item.items.map(activity => (
              <ActivityCard
                key={`${activity.title}-${activity.id}`}
                activity={activity}
              />
            ))}
          </ActivitiesGridWrapper>
        </>
      ))}
    </ActivitiesWrapper>
  );
};

export default ActivitiesGrid;
