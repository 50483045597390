function compareValues(key, order = 'asc') {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
}

function getOrderedActivities(activities) {
  const kinds = [];

  activities.map(
    activity => !kinds.includes(activity.kind) && kinds.push(activity.kind)
  );

  // .reverse() and not .sort() : Hack to keep the choosed order for restaurants.
  const orderedKinds = kinds.reverse();

  const activitiesByKinds = orderedKinds.map(kind => {
    const kindActivities = activities
      .sort(compareValues('cost'))
      .filter(activity => activity.kind === kind);

    return {
      kind,
      items: kindActivities,
    };
  });

  return activitiesByKinds;
}

export { getOrderedActivities };
