import * as React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import {
  Heading,
  Text,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
} from '@chakra-ui/core';

import Banner from '../components/guide/Banner';
import Layout from '../components/skeleton/layout';
import SEO from '../components/skeleton/seo';
import ActivitiesGrid from '../components/guide/grid';
import { mediaQueries } from '../helpers';

const MainContent = styled.div`
  margin-top: 40px;
  min-height: 85vh;
  width: 100%;
  padding: 0px 54px;

  ${mediaQueries.mobile(`
    margin-top: 0;
    padding: 0px;
  `)}
`;

const DescriptionWrapper = styled.div`
  padding: 36px 24px;
  text-align: center;

  ${mediaQueries.mobile(`
    padding: 24px 16px;
  `)}
`;

const filterByCategory = (activities, category) => {
  const filteredActivities = activities.filter(
    activity => activity.category === category
  );

  return filteredActivities;
};

export default ({ data }) => {
  const { allMarkdownRemark: edges } = data;
  let categories = [];
  let activities = [];

  if (edges && edges.edges && edges.edges.map) {
    const categoriesList = edges.edges.map(
      item => item.node.frontmatter.category
    );
    categories = [...new Set(categoriesList)];

    activities = edges.edges.map(item => item.node.frontmatter);
  }

  return (
    <Layout headerChildren={<Banner />}>
      <SEO
        title="Notre guide touristique"
        description="En plus de la location d'appartement, nous vous avons sélectionné les activitées indispensables pour passer un séjour de rêve à Chamonix, dans la vallée du mont-blanc."
      />

      <MainContent>
        <Heading textAlign="left" size="xl" color="gray.600">
          Notre guide
        </Heading>

        <DescriptionWrapper>
          <Text fontSize="xl" marginBottom={8} color="gray.500">
            Pour vous permettre de passer les meilleures vacances possibles à
            Chamonix, nous avons sélectionné pour vous les meilleures adresses
            de la vallée. Vous pourrez découvrir les beautés et les curiosités
            de la ville pour des vacances inoubliables dans la vallée du
            mont-blanc.
          </Text>
          <Text fontSize="xl" marginBottom={8} color="gray.500">
            Paysages à couper le souffle, activités pour toute la famille,
            détente entre amoureux, activités sportives, restaurants et bien
            plus encore. En hiver comme en été, en couple, seul ou en famille
            vous trouverez votre bonheur et vous repartirez des souvenirs pleins
            la tête.
          </Text>
        </DescriptionWrapper>

        {categories && categories.length > 0 ? (
          <Tabs
            variant="soft-rounded"
            variantColor="gray"
            align="center"
            width="100%"
          >
            <TabList flexWrap="wrap">
              {categories.map((item, index) => (
                <Tab key={index} marginRight={8}>
                  {item.toUpperCase()}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              {categories.map((item, index) => (
                <TabPanel key={index}>
                  <ActivitiesGrid
                    activities={filterByCategory(activities, item)}
                    category={item}
                  />
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        ) : (
          <Text>Chargement en cours</Text>
        )}
      </MainContent>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(filter: {frontmatter: {kind: {regex: "/.*\\S.*/"}}}) {
      edges {
        node {
          frontmatter {
            category
            title
            city
            icon
            kind
            pictureURL
            cost
            shortDescription
            fullDescription
            season
            link
          }
        }
      }
    }
  }
`;
