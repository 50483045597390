import React from 'react';
import styled from 'styled-components';
import { FaDollarSign, FaUmbrellaBeach, FaSnowflake } from 'react-icons/fa';
import { GoCalendar } from 'react-icons/go';
import { Text, Heading, useTheme, Box, Link, Icon } from '@chakra-ui/core';
import { ACTIVITY_ICONS, KIND_ICONS } from './icons';
import { mediaQueries } from '../../helpers';

const Card = styled.div`
  max-width: 500px;
  padding: 0;
  border-radius: 4px;
  box-shadow: 2px 3px 5px rgba(28, 26, 28, 0.2);
  overflow: hidden;
  position: relative;
  align-self: stretch;
  justify-self: center;

  ${mediaQueries.laptop(`
    margin: 24px 0px;
  `)}
`;

const HeaderCard = styled.div`
  background-image: url(${props => props.url});
  background-size: cover;
  width: 100%;
  height: ${props => (props.isSmall ? '250px' : '350px')};
`;

const BodyCard = styled.div`
  width: 100%;
  padding: 24px;
  margin-bottom: 24px;

  ${mediaQueries.tablet(`
    padding: 16px;
  `)}

  ${mediaQueries.mobile(`
    padding: 16px 8px;
  `)}
`;

const BodyHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px;
  border-bottom: 1px solid ${props => props.borderColor};
  padding-bottom: 16px;
`;

const DescriptionWrapper = styled.div`
  padding: 16px;
`;

const FooterCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background-color: ${props => props.bgColor};
  bottom: 0;
  position: absolute;
  width: 100%;
`;

const LinkWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 8px;
  margin-bottom: 16px;
`;

const getSeasonIcon = season => {
  if (season === 'ete') {
    return { name: 'Été', icon: FaUmbrellaBeach };
  }
  if (season === 'hiver') {
    return { name: 'Hiver', icon: FaSnowflake };
  }

  return { name: 'Annuel', icon: GoCalendar };
};

const ActivityCard = (props = {}) => {
  const { activity, isSmall } = props;
  const theme = useTheme();

  const { icon } = ACTIVITY_ICONS.find(item => activity.icon === item.name);
  const { icon: kindIcon } = KIND_ICONS.find(
    item => activity.kind === item.name || activity.icon === item.name
  );
  const season = getSeasonIcon(activity.season);

  const costIcons = new Array(activity.cost).fill(
    <Box as={FaDollarSign} size="16px" marginRight="4px" />,
    0,
    activity.cost
  );

  return (
    <Card>
      <HeaderCard url={activity.pictureURL} isSmall={isSmall} />
      <BodyCard>
        <BodyHeader borderColor={theme.colors.gray[300]}>
          <Heading size="md" color={theme.colors.gray[600]} display="flex">
            <Box as={icon} size="28px" marginRight="8px" color="gray.600" />
            {activity.title}
          </Heading>
          <Text display="block" fontSize="md" color={theme.colors.gray[500]}>
            {activity.city}
          </Text>
        </BodyHeader>
        <DescriptionWrapper>
          <Text fontSize="md" color="gray.500">
            {(isSmall && activity.shortDescription) || !activity.fullDescription
              ? activity.shortDescription
              : activity.fullDescription}
          </Text>
        </DescriptionWrapper>
        <LinkWrapper>
          <Link href={activity.link} isExternal color="blue.700">
            Plus d'informations <Icon name="external-link" mx="2px" />
          </Link>
        </LinkWrapper>
      </BodyCard>
      <FooterCard bgColor={theme.colors.gray[100]}>
        <Text
          fontSize="sm"
          color={theme.colors.gray[500]}
          display="flex"
          alignItems="center"
        >
          <Box as={kindIcon} size="18px" marginRight="8px" />
          {activity.kind}
        </Text>
        {activity.cost !== 0 ? (
          <Box
            display="flex"
            color={theme.colors.gray[500]}
            alignItems="center"
          >
            <Text fontSize="sm" marginRight="8px">
              Coût:
            </Text>
            {costIcons}
          </Box>
        ) : null}
        <Text
          fontSize="sm"
          color={theme.colors.gray[500]}
          display="flex"
          alignItems="center"
        >
          <Box as={season.icon} size="18px" marginRight="8px" />
          {season.name}
        </Text>
      </FooterCard>
    </Card>
  );
};

export default ActivityCard;
