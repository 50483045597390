import {
  FaRunning,
  FaUsers,
  FaSkating,
  FaSkiing,
  FaSkiingNordic,
  FaGlassCheers,
  FaSwimmer,
  FaBus,
  FaHotTub,
  FaSchool,
  FaPizzaSlice,
  FaConciergeBell,
  FaMedal,
} from 'react-icons/fa';
import {
  IoMdBicycle,
  IoIosInformationCircleOutline,
  IoMdRestaurant,
} from 'react-icons/io';
import {
  GiSkiBoot,
  GiMountaintop,
  GiOpenedFoodCan,
  GiMusicalNotes,
  GiHealthNormal,
  GiPlantsAndAnimals,
  GiFoodTruck,
} from 'react-icons/gi';

const ACTIVITY_ICONS = [
  {
    name: 'ice-skating',
    icon: FaSkating,
  },
  {
    name: 'nordic-ski',
    icon: FaSkiingNordic,
  },
  {
    name: 'ski',
    icon: FaSkiing,
  },
  {
    name: 'cycle',
    icon: IoMdBicycle,
  },
  {
    name: 'mountain',
    icon: GiMountaintop,
  },
  {
    name: 'divertissement',
    icon: FaGlassCheers,
  },
  {
    name: 'alimentation',
    icon: GiOpenedFoodCan,
  },
  {
    name: 'tourisme',
    icon: IoIosInformationCircleOutline,
  },
  {
    name: 'dance',
    icon: GiMusicalNotes,
  },
  {
    name: 'swim',
    icon: FaSwimmer,
  },
  {
    name: 'transport',
    icon: FaBus,
  },
  {
    name: 'care',
    icon: GiHealthNormal,
  },
  {
    name: 'detente',
    icon: FaHotTub,
  },
  {
    name: 'animals',
    icon: GiPlantsAndAnimals,
  },
  {
    name: 'ski-matos',
    icon: GiSkiBoot,
  },
  {
    name: 'ecole',
    icon: FaSchool,
  },
  {
    name: 'restaurant',
    icon: IoMdRestaurant,
  },
  {
    name: 'livraison',
    icon: GiFoodTruck,
  },
];

const KIND_ICONS = [
  {
    name: 'Sportif',
    icon: FaRunning,
  },
  {
    name: 'Familliale',
    icon: FaUsers,
  },
  {
    name: 'Divertissement',
    icon: FaGlassCheers,
  },
  {
    name: 'Alimentation',
    icon: GiOpenedFoodCan,
  },
  {
    name: 'Tourisme',
    icon: IoIosInformationCircleOutline,
  },
  {
    name: 'Swim',
    icon: FaSwimmer,
  },
  {
    name: 'Transport',
    icon: FaBus,
  },
  {
    name: 'Santé',
    icon: GiHealthNormal,
  },
  {
    name: 'Détente',
    icon: FaHotTub,
  },
  {
    name: 'Équipements',
    icon: GiSkiBoot,
  },
  {
    name: 'Restauration rapide',
    icon: FaPizzaSlice,
  },
  {
    name: 'Raffinée',
    icon: FaConciergeBell,
  },
  {
    name: 'Spécialités',
    icon: IoMdRestaurant,
  },
  {
    name: 'Gastronomique',
    icon: FaMedal,
  },
  {
    name: 'Livraison',
    icon: GiFoodTruck,
  },
];

export { ACTIVITY_ICONS, KIND_ICONS };
